import React from 'react';
import Header from "./component/header";
import Footer from "./component/footer";
import axios from "axios";
import { Menu } from 'antd';

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeList: [],
            notice: {}
        }
    }

    componentDidMount() {
        const params = {
            page:{
                page: 1,
                count: 50
            },
            type: 0
        };
        axios.post('news/list.do', params).then((response) => {
            if (response.data.errCode === '200') {
                this.setState({noticeList : response.data.data.data, notice: response.data.data.data[0]});
            }
        })
    }

    render() {
        const {noticeList, notice} = this.state;
        if(noticeList.length === 0){
            return null;
        }
        return (
            <div>
                <Header active={4}/>
                <div className='detail_line'/>
                <div style={{display: 'flex'}}>
                    <Menu
                        mode="inline"
                        theme={"dark"}
                        style={{width: '20%', borderRight: '1px solid rgba(209,209,209,0.42)'}}
                        defaultSelectedKeys={[notice.id + '']}
                        onClick={(item) => this.setState({notice: noticeList.find((data) => data.id === parseInt(item.key))})}
                    >
                        {
                            noticeList.map((item)=>{
                                return (
                                    <Menu.Item key={item.id + ''}>
                                        {item.ttitle}
                                    </Menu.Item>
                                )
                            })
                        }
                    </Menu>
                    <div className='vert_box' style={{width: '70%', margin: '0 5%'}}>
                        <div className='detail_title'>{notice.ttitle}</div>
                        <div style={{color: '#999999', margin: '3% auto 3% 0', fontSize: '1rem'}}>{notice.updateTime}</div>
                        <div style={{margin: '0 auto 30% 0'}} dangerouslySetInnerHTML = {{__html: notice.tmain}}/>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Product;
