import React from 'react';
import Header from "./component/header";
import Footer from "./component/footer";
import axios from "axios";

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notice: ''
        }
    }
    componentDidMount() {
        if(!this.props.match.params.id){
            this.props.history.push('/information');
        }else{
            window.scrollTo(0,0);
            axios.get('news/getNew.do?id=' + this.props.match.params.id).then((response) => {
                if (response.data.errCode === '200') {
                    this.setState({notice : response.data.data});
                }
            })
        }
    }
    render() {
        const {notice} = this.state;
        if(!notice){
            return null;
        }
        return (
            <div>
                <Header active={3}/>
                <div className='detail_line'/>
                <div className='vert_box' style={{margin: '0 18%'}}>
                    <div className='detail_title'>{notice.ttitle}</div>
                    <div style={{color: '#999999', margin: '3% auto 3% 0', fontSize: '1rem'}}>{notice.updateTime}</div>
                    <div style={{margin: '0 auto 30% 0'}} dangerouslySetInnerHTML = {{__html: notice.tmain}}/>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Product;
