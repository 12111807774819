import React from 'react';
import '../index.css'
import {Carousel} from 'antd';
import 'antd/dist/antd.css';

import img1 from '../img/banner1.png'
import img2 from '../img/banner2.png'
import img3 from '../img/banner3.png'
import img4 from '../img/banner4.png'

class HeaderCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            img: img1
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <Carousel autoplay={true}>
                <div><img src={img1} alt='' className='carousel_img'/></div>
                <div><img src={img2} alt='' className='carousel_img'/></div>
                <div><img src={img3} alt='' className='carousel_img'/></div>
                <div><img src={img4} alt='' className='carousel_img'/></div>
            </Carousel>
        );
    }
}

export default HeaderCarousel;
