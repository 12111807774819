import React from 'react';
import Header from "./component/header";
import {Carousel, Input, notification} from "antd";
import banner1 from "./img/product_banner1.png";
import banner2 from "./img/product_banner2.png";

import Footer from "./component/footer";
import about from "./img/about.png";
import axios from "axios";


class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: '',
            userName: '',
            phone: '',
            city: '',
            remark: ''
        }
    }

    addUser(){
        const {companyName, userName, phone, city ,remark} = this.state;
        if(!phone){
            notification.info({message: '请先输入您的手机号码', duration: 3});
            return null;
        }
        if(!/^\d{11}$/.test(phone)){
            notification.info({message: '请输入正确的手机号码', duration: 3});
            return null;
        }
        axios.post('company/insertContact.do', {
            companyName,
            contactPer: userName,
            contactTel: phone,
            contactStatus: 0,
            city,
            note: remark
        }).then((res) => {
            if (res.data.errCode === '200') {
                notification.success({message: '提交成功', duration: 3});
                this.setState({
                    companyName: '',
                    userName: '',
                    phone: '',
                    city: '',
                    remark: ''
                })
            } else {
                notification.success({message: res.data.errMessage})
            }

        })
    }

    render() {
        const { TextArea } = Input;
        const {companyName, userName, phone, city ,remark} = this.state;
        return (
            <div>
                <Header active={4}/>
                <Carousel autoplay={true}>
                    <div><img src={banner1} alt='' className='carousel_img'/></div>
                    <div><img src={banner2} alt='' className='carousel_img'/></div>
                </Carousel>
                <div className='vert_box' style={{margin: '0 15%', width: '70%'}}>
                    <div className='detail_title'>公司简介</div>
                    <div style={{marginTop: '3%', width: '100%', wordWrap: 'break-word', color: '#666666', fontSize: '1rem'}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;星福通是五星控股集团战略投资的区块链科技公司，深耕区块链+零工经济，提供以任务智能匹配、在线预约、支付结算、智能报税、薪酬金融和透明监管为核心的专业灵活用工服务。星福通致力于通过新技术、新业态、新模式帮助企业实现灵活用工、激活个体。星福通自主研发的区块链底层协议FS Chain和区块链服务平台FS BaaS，在人员激励、共识管理、隐私保护和跨链通讯等技术方向拥有多项核心技术专利。星福通于2019年6月完成 Pre-A轮融资并入选南京市高层次创业人才计划。
                    </div>
                    <div className='detail_title' style={{marginBottom: '3%'}}>联系我们</div>
                    <div style={{width: '100%', marginTop: '2%',display: 'flex'}}>
                        <div style={{width: '50%'}}>
                            <div className='basic_box'>
                                <div style={{minWidth : '120px'}}>企业名称</div>
                                <Input value={companyName}
                                       onChange={(event)=>this.setState({companyName: event.target.value})}/>
                            </div>
                            <div className='basic_box' style={{marginTop: '2%'}}>
                                <div style={{minWidth : '120px'}}>联系人</div>
                                <Input value={userName}
                                       onChange={(event)=>this.setState({userName: event.target.value})}/>
                            </div>
                            <div className='basic_box' style={{marginTop: '2%'}}>
                                <div style={{minWidth : '120px'}}>手机号码</div>
                                <Input value={phone}
                                       onChange={(event)=>this.setState({phone: event.target.value})}/>
                            </div>
                            <div className='basic_box' style={{marginTop: '2%'}}>
                                <div style={{minWidth : '120px'}}>所在城市</div>
                                <Input value={city}
                                       onChange={(event)=>this.setState({city: event.target.value})}/>
                            </div>
                            <div className='basic_box' style={{marginTop: '2%'}}>
                                <div style={{minWidth : '120px'}}>留言</div>
                                <TextArea value={remark} style={{height: 85}}
                                          onChange={(event)=>this.setState({remark: event.target.value})}/>
                            </div>
                        </div>
                        <div style={{width: '50%'}}>
                            <img src={about} alt='' style={{width:'90%', height: 250, marginLeft: '10%'}}/>
                        </div>
                    </div>
                    <div className='basic_box' style={{width: '100%'}}>
                        <a className='about_btn' onClick={() => this.addUser()}>提交</a>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Product;
