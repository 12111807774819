import React from 'react';
import Header from "./component/header";
import Footer from "./component/footer";
import {Carousel} from "antd";
import plan1 from "./img/plan1.png";
import plan2 from "./img/plan2.png";
import plan3_1 from "./img/plan3-1.png";
import plan3_2 from "./img/plan3-2.png";
import plan3_3 from "./img/plan3-3.png";
import plan3_4 from "./img/plan3-4.png";
import plan3_5 from "./img/plan3-5.png";
import plan3_6 from "./img/plan3-6.png";
import plan4 from "./img/plan4.png";
import banner1 from "./img/about_banner1.png";
import banner2 from "./img/about_banner2.png";
import banner3 from "./img/about_banner3.png";
import Card from "./component/card";

class Product extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Header active={2}/>
                <Carousel autoplay={true}>
                    <div><img src={banner1} alt='' className='carousel_img'/></div>
                    <div><img src={banner2} alt='' className='carousel_img'/></div>
                    <div><img src={banner3} alt='' className='carousel_img'/></div>
                </Carousel>
                <Card text={'通用众包方案'} img={plan1}/>
                <Card text={'方案的价值'} img={plan2} color={'#f5f5f5'}/>
                <div className='card_box' style={{paddingBottom: 0}}>
                    <div style={{ marginTop: '3%'}}>适用行业</div>
                        <div className='card_box' style={{paddingBottom: 0}}>
                            <div className='basic_box' style={{margin: '0 10%'}}>
                                <img src={plan3_1} alt='' style={{width: '30%'}}/>
                                <img src={plan3_2} alt='' style={{width: '30%', marginLeft: '5%'}}/>
                                <img src={plan3_3} alt='' style={{width: '30%', marginLeft: '5%'}}/>
                            </div>
                            <div className='basic_box' style={{margin: '4% 10% 0 10%'}}>
                                <img src={plan3_4} alt='' style={{width: '30%'}}/>
                                <img src={plan3_5} alt='' style={{width: '30%', marginLeft: '5%'}}/>
                                <img src={plan3_6} alt='' style={{width: '30%', marginLeft: '5%'}}/>
                            </div>
                        </div>
                </div>
                <Card text={'平台各方操作流程'} img={plan4} />
                <div style={{height: '8rem', width: '100%'}}/>
                <Footer/>
            </div>
        );
    }
}

export default Product;
