import React from 'react';
import Header from "./component/header";
import product1 from './img/product1.png'
import product2 from './img/product2.png'
import product3 from './img/product3.png'
import product4 from './img/product4.png'
import product5 from './img/product5.png'

import product6_2 from './img/product6-2.png'
import product6_3 from './img/product6-3.png'
import product6_5 from './img/product6-5.png'
import product6_6 from './img/product6-6.png'
import Footer from "./component/footer";

import {Player} from 'video-react';
import "../node_modules/video-react/dist/video-react.css";

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: [[
                '为个体工作者转变为个体工商户提供全方位服务',
                '01注册服务',
                '线上申请、线上审核、不见面审批、远程认证、一键注册、T+1出照',
                '02配套服务',
                '印章刻制、税务登记、在线开户、个税核定、专业年报年检',
                '03一键注销',
                '线上简易注销',
                '04第三方通道服务',
                '社保缴纳、商业保险等'
            ], [
                '围绕个体工商户持续提供多方面服务',
                '01运营支持',
                '在线任务发包、任务智能匹配、实名认证、在线签约、业务线上留痕、信用体系、法律顾问、业务顾问、政府监管',
                '02财务支持',
                '支付结算、智能代账、智能算税、在线申报、在线缴纳、在线开票、在线完税、资金三方托管',
                '03发展支持',
                '在线培训、保险服务、社交服务、心理咨询、普惠金融（预支、垫付、贷款）、第三方普惠医疗',
                '04工具支持',
                '任务匹配系统、区块链DC/EP支付结算系统、大数据征信系统、任务评价系统、政府协同监管平台'
            ]],
            product_text: [
                '为个体工作者转变为个体工商户提供全方位服务',
                '01注册服务',
                '线上申请、线上审核、不见面审批、远程认证、一键注册、T+1出照',
                '02配套服务',
                '印章刻制、税务登记、在线开户、个税核定、专业年报年检',
                '03一键注销',
                '线上简易注销',
                '04第三方通道服务',
                '社保缴纳、商业保险等'
            ],
            product2_img: product6_2,
            product3_img: product6_3,
            type: 0,
            timer: ''
        }
    }

    componentDidMount() {
        this.state.timer = setInterval(() => {
            this.setState({type: this.state.type === 0 ? 1 : 0}, this.changeImg(this.state.type))
        }, 3500)
    }

    componentWillUnmount() {
        if (this.state.timer != null) {
            clearInterval(this.state.timer);
        }
    }

    changeImg(type) {
        if (type === 1) {
            this.setState({
                product_text: this.state.text[0],
                product2_img: product6_2,
                product3_img: product6_3,
            })
        } else {
            this.setState({
                product_text: this.state.text[1],
                product2_img: product6_5,
                product3_img: product6_6,
            })
        }
    }

    render() {
        const {product_text, product2_img, product3_img} = this.state;
        return (
            <div>
                <Header active={1}/>
                <div style={{padding: '6% 10%', width: '100%', backgroundColor: '#f5f5f5', display: 'flex'}}>
                    <div><img src={product1} alt='' style={{width: '100%', height: '100%'}}/></div>
                    <div className='vert_box' style={{width: '100%', height: '100%', alignItems: 'flex-start'}}>
                        <div className='detail_title' style={{margin: '8% 0 5% 0'}}>企业端管理系统</div>
                        <div style={{
                            color: '#999999',
                            width: '100%',
                            fontSize: '1.1rem'
                        }}>在线分包多样化任务需求，智能匹配个体服务者。享受平台的交易可信担保和三方安全监管，保障资金安全。
                        </div>
                        <a style={{marginTop: '5%'}} href={'https://xft3.fshchain.com/company/#/'}>
                            <img src={product2} alt='' style={{width: 120, height: 25}}/>
                        </a>
                    </div>
                </div>
                <div style={{padding: '6% 10%', width: '100%', display: 'flex'}}>
                    <div className='vert_box' style={{width: '100%', height: '100%', alignItems: 'flex-start'}}>
                        <div className='detail_title' style={{margin: '8% 0 5% 0'}}>零工端接单系统</div>
                        <div style={{
                            color: '#999999',
                            width: '80%',
                            fontSize: '1.1rem'
                        }}>自主研发任务结算系统，7*24小时一键接单，在线提交审批，T+0快速提现。支持收款到银行卡、支付宝、微信钱包，安全、快捷、方便。
                        </div>
                        <div className='detail_title' style={{fontSize: '1.2rem'}}>扫码前往</div>
                        <div style={{marginTop: '5%'}}>
                            <img src={product4} alt='' style={{width: '40%', height: '40%'}}/>
                        </div>
                    </div>
                    <div><img src={product3} alt=''/></div>
                </div>
                <div className='detail_title' style={{padding: '0 10%', width: '100%', margin: 0}}>产品介绍</div>
                <div style={{padding: '6% 10%', width: '100%'}}>
                    <Player autoPlay>
                        <source src="https://xft3.fshchain.com/intro.mp4"/>
                    </Player>
                </div>
                <div className='detail_title' style={{padding: '0 10%', width: '100%', marginTop: 0}}>
                    个体工商户注册流程
                </div>
                <div style={{margin: '3% 0 8% 0', padding: '0 10%'}}>
                    <img src={product5} alt='' style={{width: '100%', height: '100%'}}/>
                </div>
                <div style={{padding: '6% 10%', width: '100%', backgroundColor: '#f5f5f5', display: 'flex'}}>
                    <div style={{width: '50%'}}>
                        <div className='detail_title' style={{marginTop: 0}}>{product_text[0]}</div>
                        <div style={{color: '#333333', marginTop: '4%', fontSize: '1.3rem'}}>{product_text[1]}</div>
                        <div style={{color: '#999999', marginTop: '2%', fontSize: '1.1rem'}}>{product_text[2]}</div>
                        <div style={{color: '#333333', marginTop: '4%', fontSize: '1.3rem'}}>{product_text[3]}</div>
                        <div style={{color: '#999999', marginTop: '2%', fontSize: '1.1rem'}}>{product_text[4]}</div>
                        <div style={{color: '#333333', marginTop: '4%', fontSize: '1.3rem'}}>{product_text[5]}</div>
                        <div style={{color: '#999999', marginTop: '2%', fontSize: '1.1rem'}}>{product_text[6]}</div>
                        <div style={{color: '#333333', marginTop: '4%', fontSize: '1.3rem'}}>{product_text[7]}</div>
                        <div style={{color: '#999999', marginTop: '2%', fontSize: '1.1rem'}}>{product_text[8]}</div>
                    </div>
                    <div style={{width: '50%', display: 'flex'}}>
                        <div><img src={product2_img} alt='' style={{width: '90%', height: '70%', marginLeft: '12%'}}
                                  onClick={() => this.changeImg(1)}/>
                        </div>
                        <div><img src={product3_img} alt=''
                                  style={{width: '90%', height: '70%', marginLeft: '2%', marginTop: '75%'}}
                                  onClick={() => this.changeImg(2)}/>
                        </div>
                    </div>

                </div>
                <Footer/>
            </div>
        );
    }
}

export default Product;
