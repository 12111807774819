import React from 'react';
import Header from "./component/header";

import msg_banner from './img/msg_banner.png'
import {Link} from 'react-router-dom'
import 'antd/dist/antd.css';
import axios from "axios";
import Footer from "./component/footer";

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeList: []
        }
    }

    componentDidMount() {
        const params = {
            page:{
                page: 1,
                count: 50
            },
            type: 1
        };
        axios.post('news/list.do', params).then((response) => {
            if (response.data.errCode === '200') {
                this.setState({noticeList : response.data.data.data});
            }
        })
    }

    render() {
        const {noticeList} = this.state;
        if(noticeList.length === 0){
            return null;
        }
        return (
            <div>
                <Header active={3}/>
                <img src={msg_banner} alt='' className='carousel_img'/>
                <div className='information_box'>
                    {
                        noticeList.map((item) => {
                            return <Link to={{ pathname: '/detail/' + item.id}}
                                         className='information_card'>
                                <div className='information_title'>{item.ttitle}</div>
                                <div className='information_text'>{item.briefIntro}</div>
                                <div style={{color: '#999999', textAlign: 'right', marginTop: 5}}>
                                    <span>{item.updateTime}</span>
                                </div>
                            </Link>
                        })
                    }
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Product;
