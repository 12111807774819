import "babel-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Home from './home';
import Product from './product';
import Information from './information';
import PersonalPlan from './personalPlan';
import CrowdPlan from './crowdPlan';
import About from './about';
import Standard from './standard';
import Detail from './detail';
import axios from "axios";

moment.locale('zh-cn');

if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    window.location.href = window.location.href.replace('/pc','');
}

axios.get(process.env.PUBLIC_URL + '/config.json').then((response) => {
    axios.defaults.baseURL = response.data.baseUrl;
    ReactDOM.render(
        <ConfigProvider locale={zhCN}>
            <Router>
                <Switch>
                    <Route path="/product" component={Product}/>
                    <Route path="/information" component={Information}/>
                    <Route path="/personalPlan" component={PersonalPlan}/>
                    <Route path="/crowdPlan" component={CrowdPlan}/>
                    <Route path="/about" component={About}/>
                    <Route path="/standard" component={Standard}/>
                    <Route path="/detail/:id" component={Detail}/>
                    <Route path="/" component={Home}/>
                </Switch>
            </Router>
        </ConfigProvider>
        , document.getElementById('root'));
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
