import React from 'react';
import {Link} from 'react-router-dom'
import Header from "./component/header";
import Footer from "./component/footer";
import Card from "./component/card";
import HeaderCarousel from "./component/headerCarousel";

import home1_1 from './img/home1-1.png'
import home1_1_check from './img/home1-1_check.png'
import home1_2 from './img/home1-2.png'
import home1_2_check from './img/home1-2_check.png'
import home2 from './img/home2.png'

import home3_1 from './img/home3-1.png'
import home3_1_check from './img/home3-1_check.png'
import home3_2 from './img/home3-2.png'
import home3_2_check from './img/home3-2_check.png'
import home3_3 from './img/home3-3.png'
import home3_3_check from './img/home3-3_check.png'
import home3_4 from './img/home3-4.png'
import home3_4_check from './img/home3-4_check.png'

import home4_1 from './img/home4-1.png'
import home4_2 from './img/home4-2.png'
import home5 from './img/home5.png'
import home6_1 from './img/home6-1.png'
import home6_2 from './img/home6-2.png'
import home6_3 from './img/home6-3.png'
import home7 from './img/home7.png'
import home8 from './img/home8.png'

import zf1 from './img/zf1.png'
import zf2 from './img/zf2.png'
import zf3 from './img/zf3.png'
import zf4 from './img/zf4.png'
import zf5 from './img/zf5.png'
import zf6 from './img/zf6.png'
import zf7 from './img/zf7.png'
import zf8 from './img/zf8.png'
import zf9 from './img/zf9.png'

import arrow from "./img/arrow.png";
import left_arrow from "./img/left-arrow.png";
import right_arrow from "./img/right-arrow.png";
import phone from './img/phone.png'
import axios from "axios";

import {Carousel, Tooltip} from 'antd';
import 'antd/dist/antd.css';


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noticeList : [],
            showPhone: false,
            home1_1_img: home1_1,
            home1_2_img: home1_2,
            home3_1_img: home3_1,
            home3_2_img: home3_2,
            home3_3_img: home3_3,
            home3_4_img: home3_4,
        }
    }

    componentDidMount() {
        const params = {page:{page: 1, count: 6}, type: 1};
        axios.post('news/list.do', params).then((response) => {
            if (response.data.errCode === '200') {
                this.setState({noticeList : response.data.data.data});
            }
        })
    }

    render() {
        const {home1_1_img, home1_2_img, home3_1_img, home3_2_img, home3_3_img, home3_4_img, noticeList} = this.state;
        if(noticeList.length === 0){
            return null;
        }
        return (
            <div style={{position: "relative", height: '100%'}}>
                <Header active={0}/>
                <HeaderCarousel/>
                <div className='home1_back'>
                    <div style={{ margin: '2% 0 3% 0'}}>企业/个人痛点</div>
                    <div className='basic_box' style={{padding: '0 10%', width: '100%', height: '60%'}}>
                        <img src={home1_1_img} alt='' style={{width: '49%', height: '100%'}}
                             onMouseOut={() => this.setState({home1_1_img : home1_1})}
                             onMouseOver={() => this.setState({home1_1_img : home1_1_check})}/>
                        <img src={home1_2_img} alt='' style={{width: '49%', height: '100%', marginLeft: '2%'}}
                             onMouseOut={() => this.setState({home1_2_img : home1_2})}
                             onMouseOver={() => this.setState({home1_2_img : home1_2_check})}/>
                    </div>
                </div>
                <Card text={'我们的解决方案'} img={home2} color={'#f5f5f5'}/>
                <div className='home1_back'>
                    <div style={{ margin: '2% 0 3% 0'}}>服务优势</div>
                    <div className='basic_box' style={{padding: '0 10%', width: '100%', height: '60%'}}>
                        <img src={home3_1_img} alt='' style={{width: '23.5%', height: '100%'}}
                             onMouseOut={() => this.setState({home3_1_img : home3_1})}
                             onMouseOver={() => this.setState({home3_1_img : home3_1_check})}/>
                        <img src={home3_2_img} alt='' style={{width: '23.5%', height: '100%', marginLeft: '2%'}}
                             onMouseOut={() => this.setState({home3_2_img : home3_2})}
                             onMouseOver={() => this.setState({home3_2_img : home3_2_check})}/>
                        <img src={home3_3_img} alt='' style={{width: '23.5%', height: '100%', marginLeft: '2%'}}
                             onMouseOut={() => this.setState({home3_3_img : home3_3})}
                             onMouseOver={() => this.setState({home3_3_img : home3_3_check})}/>
                        <img src={home3_4_img} alt='' style={{width: '23.5%', height: '100%', marginLeft: '2%'}}
                             onMouseOut={() => this.setState({home3_4_img : home3_4})}
                             onMouseOver={() => this.setState({home3_4_img : home3_4_check})}/>
                    </div>
                </div>
                <div className='home1_back'>
                    <div style={{ margin: '2% 0 3% 0'}}>区块链技术的价值</div>
                    <div className='basic_box' style={{padding: '0 10%', width: '100%', height: '60%'}}>
                        <img src={home4_1} alt='' style={{width: '49%', height: '100%'}}/>
                        <img src={home4_2} alt='' style={{width: '49%', height: '100%', marginLeft: '2%'}}/>
                    </div>
                    <div className='basic_box'>
                        <img src={home5} alt='' style={{width: '49%', height: '100%'}}/>
                    </div>
                </div>
                <div className='home1_back'>
                    <div style={{ margin: '2% 0 3% 0'}}>为个体工商户赋能</div>
                    <div className='basic_box' style={{padding: '0 10%', width: '100%', height: '60%'}}>
                        <img src={home6_1} alt='' style={{width: '32%', height: '100%'}}/>
                        <img src={home6_2} alt='' style={{width: '32%', height: '100%', marginLeft: '2%'}}/>
                        <img src={home6_3} alt='' style={{width: '32%', height: '100%', marginLeft: '2%'}}/>
                    </div>
                    <img src={home7} alt='' style={{width: '80%', height: '100%', marginTop: '2%'}}/>
                </div>
                <Card text={'合作伙伴'} img={home8}/>
                <div className='home1_back' style={{marginBottom: 0}}>
                    <div style={{ margin: '2% 0 3% 0'}}>政府支持</div>
                </div>
                <div style={{width: '80%', position: 'relative', margin: '0 10% '}}>
                    <Carousel autoplay={true} style={{width: '100%', height: '100%'}} ref="zf">
                        <div><img src={zf1} alt='' className='carousel_img'/></div>
                        <div><img src={zf2} alt='' className='carousel_img'/></div>
                        <div><img src={zf3} alt='' className='carousel_img'/></div>
                        <div><img src={zf4} alt='' className='carousel_img'/></div>
                        <div><img src={zf5} alt='' className='carousel_img'/></div>
                        <div><img src={zf6} alt='' className='carousel_img'/></div>
                        <div><img src={zf7} alt='' className='carousel_img'/></div>
                        <div><img src={zf8} alt='' className='carousel_img'/></div>
                        <div><img src={zf9} alt='' className='carousel_img'/></div>
                    </Carousel>
                    <div><img src={left_arrow} alt='' onClick={()=> this.refs.zf.prev()}
                              style={{position: 'absolute', left: 0, top: '50%'}}/></div>
                    <div><img src={right_arrow} alt=''  onClick={()=> this.refs.zf.next()}
                        style={{position: 'absolute', right: 0, top: '50%'}}/></div>
                </div>
                <div style={{marginTop: '2%', padding: '0 10%'}} className='basic_box'>
                    <span style={{color: '#333333', fontWeight: 600, fontSize: '1.2rem', marginRight: 'auto'}}>新闻资讯</span>
                    <Link to='/information' className='basic_box' style={{color: '#999999'}}>
                        查看更多
                        <img src={arrow} alt='' style={{width: 15, height: 12, marginLeft: 3, paddingTop: 1}} />
                    </Link>
                </div>
                <div style={{margin: '1% 0 3% 0', padding: '0 10%', display: 'inline-block'}}>
                    {
                        noticeList.map((item) => {
                            return <Link to={{ pathname: '/detail/' + item.id}}
                                         className='notice_box' style={{float: 'left'}}>
                                <div className='home_information'>{item.ttitle}</div>
                                <div style={{minWidth: 150}}>{item.updateTime}</div>
                            </Link>
                        })
                    }
                </div>
                <Footer/>
                <div style={{position: 'fixed',right: 50,bottom: '20%', display: 'flex', alignItems: 'center'}}>
                    <div style={{backgroundColor: '#ffffff', color: '#333333', minWidth: 210, height: 30, lineHeight: '30px',
                        paddingLeft: 10, display: this.state.showPhone?'flex':'none', fontWeight: 600}}>
                        联系电话:  +86-17887287427
                    </div>
                    <img src={phone} alt='' style={{width: 50, height: 50}}
                         onMouseOut={() => this.setState({showPhone : false})}
                         onMouseOver={() => this.setState({showPhone : true})}/>
                </div>
            </div>
        );
    }
}

export default Home;
