import React from 'react';
import '../index.css'
import 'antd/dist/antd.css';

class Card extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {text, img, color} = this.props;
        return (
            <div className='card_box' style={{backgroundColor: color?color:'#ffffff', paddingBottom: color?'5%':'3%'}}>
                <div style={{ margin: '5% 0 3% 0'}}>{text}</div>
                <img src={img} alt='' style={{width: '80%', height: '100%'}}/>
            </div>
        );
    }
}

export default Card;
