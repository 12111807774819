import React from 'react';

class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        console.log(10)
    }

    render() {
        return (
            <div>
                868
            </div>
        );
    }
}

export default Product;
