import React from 'react';
import '../index.css'
import {Link, withRouter } from 'react-router-dom'
import { Menu, Dropdown } from 'antd';
import logo from '../img/logo.png'

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0
        }
    }

    componentDidMount() {
        this.setState({active: this.props.active});
    }

    render() {
        const {active} = this.state;
        const menu = (
            <Menu slot="overlay">
                <Menu.Item>
                    <a onClick={() => this.setState({active : 4},()=>this.props.history.push('/about'))}>
                        公司介绍
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a onClick={() => this.setState({active : 4},()=>this.props.history.push('/standard'))}>
                        平台规范
                    </a>
                </Menu.Item>
            </Menu>
        );

        return (
            <div className='header_box'>
                <div style={{display: "flex", marginLeft: '10%', alignItems: 'center'}}>
                    <img src={logo} alt='' style={{width: 150, height: 30}}/>
                </div>
                <div className='header_tab'>
                    <a className='header_tab_box'
                          style={{backgroundColor: active === 0?'#F56E00':'', color: active === 0?'#FFFFFF': '#333333',
                              fontWeight: active === 0?700:500}}
                          onClick={() => this.setState({active : 0},()=>this.props.history.push('/home'))}>首页
                    </a>
                    <a className='header_tab_box'
                          style={{backgroundColor: active === 1?'#F56E00':'',color: active === 1?'#FFFFFF': '#333333',
                              fontWeight: active === 1?700:500}}
                       onClick={() => this.setState({active : 1},()=>this.props.history.push('/product'))}>产品服务
                    </a>
                    <a className='header_tab_box'
                       style={{backgroundColor: active === 2?'#F56E00':'', color: active === 2?'#FFFFFF': '#333333',
                           fontWeight: active === 2?700:500}}
                       onClick={() => this.setState({active : 2},()=>this.props.history.push('/crowdPlan'))}>解决方案
                    </a>
                    <a className='header_tab_box'
                          style={{backgroundColor: active === 3?'#F56E00':'', color: active === 3?'#FFFFFF': '#333333',
                              fontWeight: active === 3?700:500}}
                       onClick={() => this.setState({active : 3},()=>this.props.history.push('/information'))}>新闻资讯
                    </a>
                    <Dropdown overlay={menu}>
                        <a className='header_tab_box ant-dropdown-link'
                              style={{backgroundColor: active === 4?'#F56E00':'',color: active === 4?'#FFFFFF': '#333333',
                                  fontWeight: active === 4?700:500}}>
                            关于我们
                        </a>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);
