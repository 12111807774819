import React from 'react';
import '../index.css'
import vx from '../img/vx.jpg'

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='footer_box'>
                <div className='footer_title'>关于我们</div>
                <div style={{display: "flex"}}>
                    <div style={{width: '30%'}}>
                        <div>咨询电话： +86-17887287427</div>
                        <div>邮箱： xingfutong@fsh.cn</div>
                        <div>公司名称： 星福通（江苏）高科技有限公司</div>
                        <div>公司地址： 中国南京中山东路311-2号五星控股大厦</div>
                    </div>
                    <div className='footer_line'/>
                    <div className='vert_box'>
                        <div><img src={vx} alt='' style={{width: 60, height: 60}}/> </div>
                        <div style={{marginTop: 10}}>星福通微信公众号</div>
                    </div>
                </div>
                <div className='bottom_text'>星福通（江苏）高科技有限公司版权所有
                    <a href={'http://www.beian.miit.gov.cn'} style={{marginLeft: 20}}>苏ICP备19029974号-2</a>
                </div>
            </div>
        );
    }
}

export default Footer;
